import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

const vueTelInputOptions = {
  mode: 'international',
  defaultCountry: 54,
  dropdownOptions: {
    showDialCodeInList: true,
    showFlags: true,
    showDialCodeInSelection: true
  },
  inputOptions: {
    showDialCode: true,
    styleClasses: 'vue-tel-input-custom-class'
  }
}

Vue.use(Vuelidate)
Vue.use(VueTelInput, vueTelInputOptions)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
