import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const isLoggedIn = () => {
//   const authToken = localStorage.getItem('gwinn_admin_user_token')
//   const parseToken = JSON.parse(authToken)
//   return !!parseToken?.token
// }

const routes = [
  {
    path: '/',
    name: 'Customers',
    component: () => import('../views/Customers.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/clientes',
    name: 'Customers',
    component: () => import('../views/Customers.vue')
  },
  {
    path: '/cliente/:id?',
    name: 'Customer',
    component: () => import('../views/Customer.vue')
  },
  {
    path: '/agentes',
    name: 'Agents',
    component: () => import('../views/Agents.vue')
  },
  {
    path: '/agente/:id?',
    name: 'Agent',
    component: () => import('../views/Agent.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.path === '/' && isLoggedIn()) {
//     next({ path: '/clientes' })
//   } else if (to.name === 'Login' && isLoggedIn()) {
//     next({ path: '/clientes' })
//   } else if (!to.meta.allowAllUsers && !isLoggedIn()) {
//     next({
//       path: '/login'
//     })
//   } else {
//     next()
//   }
// })

export default router
