<template>
  <div class="d-flex flex-column justify-center align-center fill-height px-2">
    <v-card
      class="mx-auto py-8"
      :class="$vuetify.breakpoint.xs ? 'px-2' : 'px-8'"
      width="378"
      elevation="0"
      rounded="lg"
    >
      <div class="d-flex flex-column align-center mt-5">
        <v-img class="logo-img" src="@/assets/img/logo.png"></v-img>
      </div>

      <v-card-text class="mt-12">
        <LoginForm />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm'

export default {
  name: 'Login',

  components: {
    LoginForm
  },

  data: () => ({})
}
</script>

<style lang="scss" scoped>
</style>
