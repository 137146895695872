<template>
  <v-card
    class="nav-menu"
  >
    <v-navigation-drawer app width="300" class="pt-5">
        <v-list-item class="d-flex flex-row justify-center">
            <v-img src="@/assets/img/logo.png" class="nav-logo"></v-img>
            </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Bienvenido
          </v-list-item-title>
          <v-list-item-subtitle>
            Gwinn Financial Services
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout">
            Desloguearse
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'NavMenu',
  data () {
    return {
      items: [
        { title: 'Clientes', icon: 'mdi-account', link: '/clientes' },
        { title: 'Agentes', icon: 'mdi-account-multiple', link: '/agentes' }
      ],
      right: null
    }
  },
  methods: {
    ...mapActions(['handleLogout']),
    logout () {
      this.handleLogout()
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-menu{
    .nav-logo{
        max-width: 80%;
    }
}
</style>
