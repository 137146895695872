<template>
  <form
    @submit.prevent="submit()"
    class="d-flex flex-column"
    novalidate
    autocomplete="off"
  >
    <v-text-field
      v-model="form.email"
      label="Usuario"
      :error-messages="emailErrors"
      required
      dense
      filled
      class="rounded-t-lg font-weight-medium"
    ></v-text-field>

    <v-text-field
      v-model="form.password"
      label="Contraseña"
      :error-messages="passwordErrors"
      required
      dense
      filled
      class="rounded-t-lg font-weight-medium"
      :type="'password'"
    ></v-text-field>

    <v-btn
      type="submit"
      large
      block
      dark
      class="rounded-lg body-1"
      color="primary"
    >
      Ingresar
    </v-btn>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { userLogin } from '@/services/users'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'LoginForm',

  props: {
    validationsProps: {
      type: Object,
      default: () => ({
        form: {
          email: { required, email },
          password: { required }
        }
      })
    }
  },

  data: () => ({
    alertMessage: '',
    alertColor: '',
    form: {
      email: '',
      password: ''
    }
  }),

  validations () {
    return this.validationsProps
  },

  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.email && errors.push('Ingrese un Email válido')
      !this.$v.form.email.required && errors.push('Porfavor completa este campo')
      return errors
    },

    passwordErrors () {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push('Porfavor completa este campo')
      return errors
    }
  },

  methods: {
    ...mapActions(['handleUserLogged', 'handleUserToken', 'handleLoading']),

    submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.login()
    },

    async login () {
      try {
        this.handleLoading(true)
        const res = await userLogin({
          email: this.form.email,
          password: this.form.password
        })

        if (res.data.status === 200) {
          // this.saveUser({ token: res.data.access_token, user: res.data.user })
          this.handleUserLogged(res.data.user)
          this.handleUserToken(res.data.authentication)
          // this.alertMessage = `${res.data.user.name} has ingresado con éxito!`
          // this.alertColor = 'success'
          this.$router.push({ path: '/clientes' }).catch(() => {})
        } else {
          alert(res.data.response)
          console.log(res)
        }
      } catch (error) {
        console.log(error)
        // this.alertMessage = error.data?.message
        // this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.bg-pink {
  background-color: $main-color !important;
}
</style>
