import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isUserLogged: false,
    userToken: '',
    userLogged: null,
    customerToEdit: null,
    agentToEdit: null,
    customers: [],
    agents: [],
    showLoading: false
  },
  mutations: {
    setUserLogged (state, val) {
      state.userLogged = val
      state.isUserLogged = true
    },
    setUserLoggedOut (state, val) {
      state.userLogged = null
      state.isUserLogged = false
      localStorage.removeItem('gwinn_admin_user_token')
    },
    setUserToken (state, val) {
      state.userToken = val
      localStorage.setItem('gwinn_admin_user_token', val)
    },
    setCustomerToEdit (state, val) {
      state.customerToEdit = val
    },
    setCustomers (state, val) {
      state.customers = val
    },
    setAgentToEdit (state, val) {
      state.agentToEdit = val
    },
    setAgents (state, val) {
      state.agents = val
    },

    setLoading (state, payload) {
      state.showLoading = payload
    }
  },
  actions: {
    handleUserLogged ({ commit }, payload) {
      commit('setUserLogged', payload)
    },
    handleUserToken ({ commit }, payload) {
      commit('setUserToken', payload)
    },
    handleLogout ({ commit }) {
      commit('setUserLoggedOut', false)
    },
    customerEdition ({ commit }, payload) {
      commit('setCustomerToEdit', payload)
    },
    storeCustomers ({ commit }, payload) {
      commit('setCustomers', payload)
    },
    agentEdition ({ commit }, payload) {
      commit('setAgentToEdit', payload)
    },
    storeAgents ({ commit }, payload) {
      commit('setAgents', payload)
    },

    handleLoading ({ commit }, payload) {
      commit('setLoading', payload)
    }
  },
  getters: {
    isUserLogged: state => state.isUserLogged,
    userToken: state => state.userToken,
    userLogged: state => state.userLogged,
    customers: state => state.customers,
    agents: state => state.agents,
    showLoading: state => state.showLoading
  }
})
