<template>
  <v-app>
    <v-main v-if="isUserLogged">
      <NavMenu />
      <router-view/>
    </v-main>
     <Login v-else />
    <AppLoading />
  </v-app>
</template>

<script>
import Login from '@/views/Login'
import NavMenu from '@/components/NavMenu'
import AppLoading from '@/components/AppLoading'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Login,
    NavMenu,
    AppLoading
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['isUserLogged'])
  }
}
</script>
